import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = props => (
  <Layout>
    <SEO
      title="Services offered by Lou Guarini Plumbing & Heating"
      description="Our plumbing services include heating, water heaters, clogged drains and leaks."
    />
    <div class="container">
      <h1>Services</h1>

      <h3 id="heating">Heating</h3>
      <div class="row">
        <div class="col-sm-8">
          <p>
            Your heating system is one of the most important parts of your home.
            Having a warm house is easy to take for granted when it "just
            works". Unfortunately heating units tend to break on the coldest
            days of the year, when they are working the hardest. There might be
            an easy fix for this issue or it could be something more serious.
          </p>
          <p>
            This is one of the benefits of doing preventative maintenance on
            your boiler. There are a lot of safety issues and costly repairs
            that can be avoided by doing so.{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.plumbcare.com/blog/2016/05/5-reasons-why-you-should-service-your-boiler-annually"
            >
              Annual service
            </a>{" "}
            of your boiler can increase its lifespan and ensure it is operating
            safely.
          </p>
          <h4>Signs your heating system might need to be repaired</h4>
          <ul>
            <li>Your house is cold</li>
            <li>There is water near your heating unit</li>
            <li>Your carbon monoxide detector is going off</li>
            <li>You hear loud banging from your pipes</li>
            <li>The heating unit keeps shutting off</li>
            <li>You haven't had it serviced in a couple of years</li>
          </ul>
        </div>
        <div class="col-sm-4 d-none d-sm-block">
          <Img
            fluid={props.data.boiler.childImageSharp.fluid}
            alt="boiler service and install"
          />
        </div>
      </div>
      <h3 id="water-heater">Water Heaters</h3>
      <div class="row">
        <div class="col-sm-8">
          <p>
            Water heaters keep the water you use in your sinks and showers hot.
            Unfortunately they do not have an extremely long life span and will
            need to be replaced about{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bobvila.com/articles/how-long-does-a-water-heater-last/"
            >
              every 10 years
            </a>
            . When you notice your water is not as hot as it used to be there is
            most likely an issue with the water heater.
          </p>
          <h4>Signs your water heater might need to be replaced</h4>
          <ul>
            <li>You're taking a cold shower</li>
            <li>There's water on the floor near the water heater</li>
            <li>It was installed over 10 years ago</li>
            <li>Rust on the body of the unit</li>
            <li>Corrosion on the water lines</li>
          </ul>
        </div>
        <div class="col-sm-4 d-none d-sm-block">
          <Img
            fluid={props.data.waterHeater.childImageSharp.fluid}
            alt="water heater install"
          />
        </div>
      </div>
      <h3 id="bathroom">Bathrooms</h3>
      <div class="row">
        <div class="col-sm-8">
          <p>
            Leaks in your bathroom could end up increasing your water bill
            substantially. The common culprits are things like a toilet that
            won't stop running or shower head that is constantly dripping. These
            leaks can add $100's to your water bill every month,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.thewaterscrooge.com/blog/how-much-water-does-a-running-toilet-use"
            >
              no exaggeration!
            </a>
            .
          </p>
          <h4>Common issues and upgrades in your bathroom</h4>
          <ul>
            <li>Toilet doesn't stop running</li>
            <li>Shower head or sink faucet is dripping</li>
            <li>Tub is draining slowly or completely clogged</li>
            <li>Upgrading outdated vanity and toilet</li>
            <li>Upgrading shower head and tub spout</li>
          </ul>
        </div>
        <div class="col-sm-4 d-none d-sm-block">
          <Img
            fluid={props.data.toilet.childImageSharp.fluid}
            alt="bathroom toilet sink and shower install"
          />
        </div>
      </div>
      <h3 id="kitchen">Kitchens</h3>
      <div class="row">
        <div class="col-sm-8">
          <p>
            Your kitchen is probably the most expensive area of your home and
            rightly so. Many families spend most of their time together in the
            kitchen. Kitchen sinks are known to get clogged and a new dishwasher
            can be difficult to install. We can help you with these issues and
            more.
          </p>
          <h4>Common issues and upgrades in your Kitchen</h4>
          <ul>
            <li>Clogged sink drain</li>
            <li>Leaks under the sink</li>
            <li>Replacing broken dishwasher</li>
            <li>Installing water line for new refrigerator</li>
            <li>Hookup up gas line to new stove or oven</li>
            <li>Upgrading your faucet</li>
          </ul>
        </div>
        <div class="col-sm-4 d-none d-sm-block">
          <Img
            fluid={props.data.faucet.childImageSharp.fluid}
            alt="kitchen faucet install"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default ServicesPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    boiler: file(relativePath: { eq: "boiler.jpg" }) {
      ...fluidImage
    }
    toilet: file(relativePath: { eq: "toilet.jpg" }) {
      ...fluidImage
    }
    waterHeater: file(relativePath: { eq: "water-heater.jpg" }) {
      ...fluidImage
    }
    faucet: file(relativePath: { eq: "faucet.jpg" }) {
      ...fluidImage
    }
  }
`
